<template>
	<div>
		<div class="w_100 headbar flexBetween" style="padding:0;border:0;">
			<div style="">


				<!-- <el-select size="small" v-model="searchForm.status" placeholder="保险购买" clearable>
					<el-option label="已购保险" :value="1"></el-option>
					<el-option label="未购保险" :value="0"></el-option>
				</el-select> -->
        <el-select v-model="searchForm.jyqx" placeholder="就业去向" clearable style="margin-right: 15px;">
          <el-option v-for="(jyqx,idx) in jyqxArr" :label="jyqx" :value="jyqx" :key="idx"></el-option>
        </el-select>


				<span>关键字:</span>
				<el-input placeholder="学生姓名或手机号" v-model="searchForm.keyword" class="input-with-select"
					style="width:250px;margin-left: 10px" clearable></el-input>



				<el-button icon="el-icon-search" @click="page.current_page = 1; getDataList()" style="margin-left:10px"
					size="small" type="primary">搜索</el-button>

				<!-- <el-button icon="el-icon-download" @click="dialogShow = true" style="margin-left:10px" size="small"
					type="primary" title="请先选择班级">导出就业
					名册</el-button> -->



			</div>
			<el-button @click="initFullTables" size="middle" type="text" title="全屏展示表格，也可双击表格全屏展示"> <i
					class="el-icon-full-screen" style="font-size:24px!important;"></i></el-button>
		</div>
		<div class="w_100 h_100 bcontent">


			<div class="w_100 h_100 flexCloumn" id="myFullTable" @dblclick="initFullTables"
				style="background-color: #fff;">
				<div v-if="tableData.length > 0" class="w_100 borderbox" style="border: 1px solid #ccc; padding: 10px;">
					<span style="margin-right: 40px;">学校：{{ tpmid == 'all' ? '全部' : (
						tableData.length > 0 ? tableData[0].schoolName : '') }}</span>
					<span style="margin-right: 40px;">班级：{{ tableData[0].class_name }}</span>
					<span style="margin-right: 40px;">毕业学生数：{{ tableData[0].by_stu_count }}</span>
					<span style="margin-right: 40px;">班级学生数：{{ tableData[0].zs_stu_count }}</span>
					<span style="margin-right: 40px;">班主任姓名及联系电话：{{ tableData[0].brz_name }}&nbsp;&nbsp;{{tableData[0].bzr_phone }}</span>

				</div>

				<el-table id="myFullTable" class="flex_1 el-table" :data="tableData" row-key="id" border header-row-class-name="mytableheader" stripe
					size="mini" height="100%" @selection-change="handleSelectionChange"
					style="width:fit-content;width:100%;">

					<!-- <el-table-column type=" selection" width="55"> -->
					<!-- </el-table-column> -->
					<el-table-column type="index" label="序号" width="55px" align="center">
						<template slot-scope="scope">
							{{ scope.$index + 1 + (page.current_page - 1) * page.per_page }}
						</template>

					</el-table-column>
					<!-- <el-table-column label="学校" prop="platform" min-width="150" align="center"></el-table-column> -->
					<el-table-column label="姓名" prop="stu_name" width="100" align="center"></el-table-column>
					<el-table-column label="性别" prop="gender" width="80" align="center"></el-table-column>
					<el-table-column label="年龄" prop="age" width="80" align="center"></el-table-column>
					<el-table-column label="学制" prop="xuezhi" width="80" align="center"></el-table-column>
          <el-table-column label="就业去向" prop="jyqx" width="80" align="center"></el-table-column>
          <el-table-column label="专业名称" prop="xibu_name" width="140" align="center"></el-table-column>
          <el-table-column label="专业类别" prop="zhuanye_type" width="140" align="center"></el-table-column>
          <el-table-column label="专业代码" prop="zydm" width="120" align="center"></el-table-column>
<!--					<el-table-column label="户籍所在地（省、市、区/县）" prop="jtzz" width="150" align="center"></el-table-column>-->
					<el-table-column label="最高职业技能等级/职业资格证" prop="prize_skill" width="140" align="center"></el-table-column>
					<el-table-column label="全日制升学" align="center">

						<el-table-column label="学校名称" prop="enter_name" width="180" align="center">
							<template slot-scope="scope">
								{{ scope.row.quxiang == '升入高一级学校' ? scope.row.enter_name : '' }}
							</template>
						</el-table-column>
						<el-table-column label="学历" prop="enter_type" width="100" align="center">
							<template slot-scope="scope">
								{{ scope.row.quxiang == '升入高一级学校' ? scope.row.enter_type : '' }}
							</template>
						</el-table-column>

					</el-table-column>
					<!-- <el-table-column label="毕业去向" prop="quxiang" width="180" align="center"></el-table-column> -->
					<el-table-column label="就业状况" align="center">

						<el-table-column label="单位名称" prop="enter_name" width="180" align="center">
							<template slot-scope="scope">
								{{ scope.row.quxiang != '升入高一级学校' ? scope.row.enter_name : '' }}
							</template>
						</el-table-column>
						<el-table-column label="单位地址（省、市）" prop="enter_addr" width="130"
							align="center"></el-table-column>
						<el-table-column label="单位地点类别" prop="didian" width="120" align="center"></el-table-column>
						<el-table-column label="单位性质" prop="dwxz" min-width="150" align="center"></el-table-column>
						<el-table-column label="所属产业" prop="chanye" width="120" align="center">
							<template slot-scope="scope">
								{{ scope.row.quxiang != '升入高一级学校' ? scope.row.chanye : '' }}
							</template>
						</el-table-column>
						<el-table-column label="部门" prop="stu_dept" width="120" align="center"></el-table-column>
						<el-table-column label="岗位" prop="stu_job" width="120" align="center"></el-table-column>
						<el-table-column label="是否对口就业" prop="duikou" width="80" align="center"></el-table-column>
						<el-table-column label="是否学校推荐" prop="qudao" width="80" align="center">
							<template slot-scope="scope">
                {{ scope.row.quxiang != '升入高一级学校' ? (scope.row.qudao == '学校推荐' ? '是' : scope.row.qudao == '自联' ? '否' : scope.row.qudao) : '' }}
							</template>

						</el-table-column>
					</el-table-column>

					<el-table-column label="劳 动 保 障" align="center">

						<el-table-column label="有否劳动合同" prop="hetong" width="80" align="center">

						</el-table-column>
						<el-table-column label="有否三险五险" prop="enter_type" width="80" align="center">
							<template slot-scope="scope">
								{{ scope.row.wuxian.replace('一金', '') }}
							</template>
						</el-table-column>
						<el-table-column label="有否公积金" prop="gongjijin" width="80" align="center">

						</el-table-column>
						<el-table-column label="平均月工资(元)" prop="salary" width="100" align="center">
							<template slot-scope="scope">
								{{ scope.row.quxiang != '升入高一级学校' ? scope.row.salary : '' }}
							</template>
						</el-table-column>

					</el-table-column>
          <el-table-column label="联 系 电 话" align="center">

            <el-table-column label="本人电话" prop="lxdh" width="130" align="center"></el-table-column>
            <el-table-column label="家长电话" prop="lxdh" width="130" align="center"></el-table-column>
          </el-table-column>

					<el-table-column label="就业是否满意" prop="manyidu" width="80" align="center"></el-table-column>
					<el-table-column label="备注" prop="bz" width="150" align="center"></el-table-column>

					<el-table-column fixed="right" label="操作" width="80">
						<template slot-scope="scope">
							<el-button @click="viewInfo(scope.row)" type="text" size="small">详细</el-button>

						</template>
					</el-table-column>
				</el-table>

				<div style="padding-top: 10px;">
					<el-pagination background @current-change="handlePageChange" :current-page="page.current_page"
						:page-size="page.per_page" :total="page.count"
						layout="total, prev, pager, next"></el-pagination>
				</div>


			</div>

		</div>

		<jiuyeinfo v-if="infoShow" :id="sxId"></jiuyeinfo>


		<el-dialog class="cus_dialog " title="请选择导出班级" :visible.sync="dialogShow" :close-on-click-modal="false"
			width="600px" height="30%">

			<el-tree ref="clsTree" :data="TreeData" show-checkbox node-key="name" default-expand-all
				:props="defaultProps">
			</el-tree>


			<div style="text-align: right; padding: 20px;">

				<el-button type="primary" icon="el-icon-download" @click="exportMingceZip">确认导出</el-button>

			</div>

		</el-dialog>

	</div>
</template>

<script>
import jiuyeinfo from './jiuyeinfo.vue'
import { initTable } from '../../../utils/FullScreen.js'
export default {
	components: {
		jiuyeinfo
	},
	name: "jymingce",
	props: ['tpmid'],
	data() {
		return {
			dialogShow: false,
			dialogshow1: false,
			infoShow: false,
			sxId: 0,
			searchForm: {
				class_name: "",
				keyword: "",
				status: "",
				school_id: ""

			},
			TargetPmid: 0,
			tableData: [],
			TreeData: [],
			SchoolList: [],
			checkedRows: [],
			page: {
				count: 0,
				current_page: 1,
				per_page: 20,
				total_page: 0
			},
			defaultProps: {
				label: 'name',
				value: 'name',
				children: 'children',
				emitPath: false,
				checkStrictly: true,

			},
			shixi: null,
			schoolName: "",
			tjData: {},
      jyqxArr: ['就业','升学','参军','出国','创业','待业','其他'],
		}
	},
	mounted() {

		this.getDataList()


	},
	methods: {
		getSelectType(e) {
			let node = this.$refs['firstCascader'].getCheckedNodes()
			if (node[0]) {
				let path = node[0].pathNodes[0].label
				if (node[0].pathNodes.length >= 2) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = node[0].pathNodes[1].label
				} else if (node[0].pathNodes.length >= 1) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = ""
				} else {
					this.searchForm.xibu_name = ""
					this.searchForm.class_name = ""
				}
			} else {
				this.searchForm.xibu_name = ""
				this.searchForm.class_name = ""

			}

		},
		tongbuData() {
			this.$http.post("/api/tongbu_shixi", {
				loading: true,
				tpmid: this.TargetPmid
			}).then(res => {
				this.$message.success("同步成功")
				this.page.current_page = 1;
				this.getDataList();
			})
		},
		getDataList() {
			let data = {
				page: this.page.current_page,
				keyword: this.searchForm.keyword,
				xibu_name: this.searchForm.xibu_name,
				class_name: this.searchForm.class_name,
				status: this.searchForm.status,
				school_id: this.tpmid,
				tpmid: this.tpmid,
				showloading: true,
				all: true,
        jyqx: this.searchForm.jyqx,
			}
			this.$http.post("/api/t_sch_jymingce_list", data).then(async res => {
				for (let item of res.data.data) {

		// 			item.bzr_phone = await this.decryptWord(item.bzr_phone)
        //   item.lxdh = await this.decryptWord(item.lxdh)
        //   item.jz_phone = await this.decryptWord(item.jz_phone)
					item.bzr_phone = '***'
					item.lxdh = '***'
					item.jz_phone = '***'
				}

				this.tableData = res.data.data
				this.page = res.data.page
			})
			console.log(data);
			this.$http.post("/api/get_jiuye_tjtable2", data).then(async res => {
				this.tjData = res.data
			})




		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		viewInfo(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name
			this.sxId = e.id

			this.infoShow = true
		},
		uploadFiles(e) {
			this.$http.post("/api/import_shixixls", {
				url: e.src
			}).then(res => {

				this.getDataList()
				this.$message.success("导入成功")
				if (res.data.msg.length > 0) {
					let html = ''
					for (let msg of res.data.msg) {
						html += "<div style='font-size:12px'>" + msg.stu_name + ":" + msg.msg + "</div>"
					}
					this.$alert(html, '未导入数据', {
						dangerouslyUseHTMLString: true
					});
				}
				this.dialogshow1 = false


			})
		},
		deleteRow(e) {

			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/jymingce_delete", {
					id: e.id
				}).then(res => {
					this.getDataList()
					this.$message({
						type: 'success',
						message: '删除成功!'
					});

				})
			}).catch(() => {

			});

		},
		downMingce() {

			this.$http.post("/api/jymingce_file_down", {
				class_name: this.searchForm.class_name,
				school_id: this.tpmid
			}).then(res => {
				if (res.data.file) {
					window.open(res.data.file, "_blank")
				} else {
					this.$message({
						type: 'error',
						message: '该班级未上传就业名册!'
					});
				}
			})
		},
		handleSelectionChange(val) {
			this.checkedRows = val
		},
		deleteMore() {
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/jymingce_delmore", {
					ids: this.checkedRows.map(e => {
						return e.id
					})
				}).then(res => {
					this.getDataList()
					this.$message({
						type: 'success',
						message: '删除成功!'
					});

				})
			}).catch(() => {

			});
		},
		exportMingceZip() {
			let clsNames = this.$refs['clsTree'].getCheckedKeys(true)
			this.$http.post("/api/export_jymc2024", {
				clsNames
			}).then(res => {

				this.dialogShow = false

				window.open(res.data.url, "_blank")

			})
		}

	}
}
</script>